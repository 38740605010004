@font-face {
    font-family: 'Codec Pro Bold';
    src: url('../fonts/Codec\ Pro\ Bold.ttf');
}
@font-face {
    font-family: 'General Sans Semibold';
    src: url('../fonts/GeneralSans-Semibold.otf');
}
@font-face {
    font-family: 'General Sans Medium';
    src: url('../fonts/GeneralSans-Medium.ttf');
}
@font-face {
    font-family: 'General Sans Bold';
    src: url('../fonts/GeneralSans-Bold.otf');
}
@font-face {
    font-family: 'General Sans';
    src: url('../fonts/GeneralSans-Regular.otf');
}
@font-face {
    font-family: 'Codec Pro Heavy';
    src: url('../fonts/Codec\ Pro\ Heavy.ttf');
}

$header-color: linear-gradient(127.55deg, #141E30 3.73%, #243B55 92.26%);
$theme-color: linear-gradient(90deg, #ED1F23 0.12%, #ED1F23 8.11%, #ED1F23 15.51%, #ED2023 22.41%, #EE2023 28.89%, #EE2123 35.05%, #EF2223 40.99%, #EF2323 46.78%, #F02423 52.53%, #F12523 58.33%, #F12723 64.27%, #F22823 70.43%, #F32A23 76.91%, #F42C23 83.81%, #F52D23 91.21%, #F62F23 99.2%);
$text-color:  #1A1616;
$theme-color-hex: #ED1F23;
$theme-matching: #FFEBEB;
$theme-border: #504D4D;

$title-font-size: 2.438rem;
$subHead-font-size: 1.25rem;
$normal-font-size: 1rem;

$primary-font: Poppins, sans-serif;
$codec-bold: Codec Pro Bold;
$para-font: 'General Sans';
$secondary-font: Roboto, sans-serif;


@mixin generalSans($weight) {
    @if($weight == 600){
        font-family: General Sans Semibold !important;
    }@else if($weight == 500){
        font-family: 'General Sans Medium';
    }@else if($weight == 700){
        font-family: 'General Sans Bold';
    }@else if ($weight == 400) {
        font-family: 'General Sans';
    }
}

.MuiFormControl-root {
    @include generalSans(500);
    font-size: 13px;
    color: #1A1616;
}

.font-gs-md{
    font-family: 'General Sans Medium' !important;
}

.verifiedBox {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    h3 {
        color: #1A1616;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 130%;
    }
    h4 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 130%;
        color: #000000;

    }
    .MuiLink-root {
        text-decoration: none;
        @include generalSans(500);
        font-size: 13px;
        line-height: 18px;
        display: flex;
        color: #0C8BF4;

    }
}

.Add-image {
    .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd {
        padding-right: 0 !important;
        padding-left: 10px !important;
        padding-top: 13px !important;
        padding-bottom: 13px !important;
    }
    .MuiInputBase-root.MuiOutlinedInput-root{
        padding-right: 8px;
    }
}