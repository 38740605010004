@font-face {
  font-family: "Codec Pro Bold";
  src: url("../fonts/Codec Pro Bold.ttf");
}
@font-face {
  font-family: "General Sans Semibold";
  src: url("../fonts/GeneralSans-Semibold.otf");
}
@font-face {
  font-family: "General Sans Medium";
  src: url("../fonts/GeneralSans-Medium.ttf");
}
@font-face {
  font-family: "General Sans Bold";
  src: url("../fonts/GeneralSans-Bold.otf");
}
@font-face {
  font-family: "General Sans";
  src: url("../fonts/GeneralSans-Regular.otf");
}
@font-face {
  font-family: "Codec Pro Heavy";
  src: url("../fonts/Codec Pro Heavy.ttf");
}
.MuiFormControl-root {
  font-family: "General Sans Medium";
  font-size: 13px;
  color: #1A1616;
}

.font-gs-md {
  font-family: "General Sans Medium" !important;
}

.verifiedBox {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.verifiedBox h3 {
  color: #1A1616;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 130%;
}
.verifiedBox h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  color: #000000;
}
.verifiedBox .MuiLink-root {
  text-decoration: none;
  font-family: "General Sans Medium";
  font-size: 13px;
  line-height: 18px;
  display: flex;
  color: #0C8BF4;
}

.Add-image .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd {
  padding-right: 0 !important;
  padding-left: 10px !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}
.Add-image .MuiInputBase-root.MuiOutlinedInput-root {
  padding-right: 8px;
}/*# sourceMappingURL=style.css.map */