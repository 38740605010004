.apple-btn {
  border-color: #000000;
  font-family: Helvetica;
  font-size: 14px;
  background-color: black;
  color: white;
  padding: 10px 20px;
  border-width: 1px;

  border-radius: 100px;
  text-transform: none;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.apple-btn img {
  margin-right: 0.5rem;
}

.orSeperator {
  text-align: center;
  margin: 1rem 0;
  font-weight: bold;
}